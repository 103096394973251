@import '../../config/constants.scss';

.admin-login-app {
  min-height: 100vh;
  .admin-login-header {
    background-color: $light-blue;
    border-bottom: 1px solid $light-blue-border;
  }
  .admin-login-form {
    max-width: 600px;
  }
}