@import "../../../../../config/constants.scss";

.passingSaving {
  padding: 100px 50px;

  .btnSubmit {
    float: right;
    width: 40%;
    height: 40px;
  }
  .textCardSwap {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: $dark-blue-text;
    position: absolute;
    text-align: center;
    top: 40px;
    transition: all 1s ease-out;
    opacity: 0;

    &.active {
      transition: all 1s ease-out;
      opacity: 1;
    }
  }

  .rowContent {
    margin-bottom: 20px;
    margin-bottom: 32px;

    .faArrows {
      transition: all 1s ease-out;
      opacity: 0;
      .faLeft {
        position: absolute;
        top: 65px;
        font-size: 24px;
        left: -13px;
        z-index: 1;
        color: #f95638ed;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .faRight {
        position: absolute;
        top: 65px;
        font-size: 24px;
        right: -13px;
        z-index: 1;
        color: #f95638ed;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      &.active {
        visibility: visible;
        transition: all 1s ease-out;
        opacity: 1;
      }
    }

    &.mobile {
      // margin: none;
      .col-6 {
        padding: 0px;
      }
      .row {
        margin: 0px 8px;
      }
    }
  }
  .cardSecond {
    opacity: 1;
    transition: all 500ms ease-out;
    position: relative;
    left: 0;

    &.active {
      opacity: 0;
      transition: all 500ms ease-out;
    }
  }
  .swapActive {
    position: relative;
    left: -150%;
    top: -10px;
    transition: left 500ms ease-out;
  }
  .contentSavings {
    img {
      max-width: 90px;
      max-height: 90px;
    }
    .rejectImg {
      width: 30px;
      position: absolute;
      right: 50px;
      bottom: -6px;
    }
    padding-top: 25px;
    padding-bottom: 20px;
    text-align: center;
    .content-align-center {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .details {
      text-align: initial;
      .row {
        height: 27px;
        text-align: inherit;
        .col {
          padding: 0px;
        }
      }
      h3 {
        font-size: 13px;
        font-weight: 600;
        color: $dark-blue-text;
        text-transform: capitalize;
      }
      h4 {
        line-height: 19px;
        font-size: 13px;
        color: $dark-blue-text;
        width: 80%;
      }
      a {
        font-size: 14px;
        color: #f95638;
        float: right;
        position: relative;
        top: 22px;
      }
      p {
        opacity: 0.8;
        color: $dark-blue-text;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
      }

      &.floatRight {
        position: absolute;
        right: 23px;
        top: 31px;
      }
      .pop {
        svg {
          position: relative;
          top: -5px;
          left: 10px;
        }
      }
    }
    .orderGuideIcon {
      position: absolute;
      top: -7px;
      right: 5px;
      .iconText {
        background: url("../../../../../assets/images/bookmark-red.png") no-repeat;
        width: 117px;
        font-size: 9px !important;
        color: $dark-blue-tag-text;
        text-transform: uppercase;
        letter-spacing: 1px !important;
        font-weight: 600;
      }
      .iconTextM {
        top: 3px !important;
      }

      .right {
        background-position: right center;
        padding: 1px 15px 4px 4px;
      }
    }
  }

  .contentButton {
    margin: auto;
    text-align: center;
    button {
      width: 80%;
      margin-bottom: 10px;
      font-size: 14px;
    }
    svg {
      margin-right: 5px;
    }
  }

  &.mobile {
    padding: 0px 5px;
    margin-bottom: 42px;
    .card {
      height: 300px;
    }
    .row {
      margin: 0px;
    }
    .orderGuideIcon {
      top: 2px;
      right: 1px;
    }
    .textMobile {
      font-size: 14px;
      text-align: center;
      padding: 13px 0px 10px;
      color: $dark-blue-text;
      opacity: 0.8;
    }
    hr {
      margin: 0px !important;
    }
    .faArrows {
      .faLeft {
        top: auto;
        bottom: 21px;
        left: 38px;
      }
      .faRight {
        top: auto;
        bottom: 21px;
        right: 38px;
      }
    }
    .contentRowBtn {
      .col-6 {
        padding: 0px;
      }
      button {
        width: 100%;
      }
    }
    .swapActive {
      left: -100%;
      top: 0;
    }
    .textCardSwap {
      font-size: 13px;
      top: 100px;
      padding: 0px 15px;
    }
    .details{
      h4{
        margin-top:70px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.footerButton {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  button {
    width: 100%;
    height: 50px;
  }
}
