@import "../../../../../config/constants.scss";

.contentShippingAndPayment {
  padding: 40px 100px;
  h1 {
    font-weight: 500;
    font-size: 17px !important;
    color: $dark-blue-text;
    margin: 10px 0px 40px 0px;
  }
  .row {
    margin-bottom: 30px;

    svg {
      color: $dark-blue-text;
    }
    textarea {
      height: 150px;
    }
    button {
      width: 100%;
      height: 40px;
    }
    .buttonD {
      width: 80px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .custom-checkbox {
    margin-bottom: 15px;
    font-size: 14px;
  }
  .marginL200 {
    margin-left: 320px;
  }
  .marginL150 {
    margin-left: 210px;
  }
  .marginL30 {
    margin-left: 50px;
  }
}

.modal-shipping-payment {
  height: 100%;
  .modal-content {
    max-height: none !important;
    height: 100%;
    .iconBack {
      position: absolute;
      top: 18px;
      left: 11px;
      color: #143357;
      opacity: 0.9;
    }
    .modal-title {
      font-size: 15px;
    }
    .paymentMethod {
      .row {
        margin-bottom: 10px;
      }
      p {
        margin: auto;
        font-size: 15px;
        color: #143357;
      }
      a {
        margin: auto;
        font-size: 15px;
      }
      button {
        margin: auto;
        width: 93%;
        height: 45px;
      }
    }
  }
}

.modal-card-list {
  padding: 0px 15px;
  margin-top: 30% !important;
  .modal-content {
    height: 350px;
    overflow: auto;
    .modal-header {
      border-bottom: 1px solid #dce8f2;

      h5 {
        position: absolute;
      }
    }
    .row {
      border-bottom: 1px solid #dce8f2;
      margin-bottom: 20px;
    }
    p {
      margin-top: 6px;
      font-size: 13px;
    }
  }
}

.cardItem {
  border-bottom: 1px solid #dce8f2 !important;
  margin-bottom: 20px !important;

  p {
    font-size: 13px !important;
    margin-top: 4px !important;
  }
  svg {
    margin-right: 13px !important;
    color: $dark-blue-text;
  }
}
