@import "../../../config/constants";

.confirmationModal {
  width: 450px !important;

  .modal-header {
    color: $dark-blue-text;
  }
  .modal-body {
    margin: auto;
    .row {
      margin-bottom: 10px;
      padding: 0px 10px;
    }
    svg {
      margin: auto;
      color: #6ed79a;
    }
    p {
      font-size: 13px;
      color: $dark-blue-text;
    }
    h1 {
      font-size: 15px;
      text-align: center;
      margin: auto;
      font-weight: 600;
    }
  }
  &.mobile {
    width: auto !important;
    margin: 70% 10px;
  }

  button {
    width: 50%;
    margin: auto;
  }
}
