@import "../../../../../config/constants.scss";

.recipe-costing-header {
  background-color: $light-blue;
  border-bottom: 1px solid $light-blue-border;

  .recipe-costing-actions {
    padding: 1.5rem 2rem;
    color: $light-blue-text;
    display: flex;

    .checkbox-container {
      margin-right: 1rem;
    }

    > .col {
      display: flex;
      align-items: center;

      > [class^="col-"],
      .col {
        display: flex;
        align-items: center;
      }

      .col {
        align-items: center;
        display: flex;
      }

      &:first-child {
        .col {
          align-items: center;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    .recipe-costing-action-buttons {
      margin: 0 1rem;
      .btn {
        color: inherit;
        background: transparent;
        border: transparent;
        border-radius: 4px !important;

        &:hover {
          color: $white;
          background-color: $light-blue-text;
        }

        &:active {
          background-color: $light-blue-text;
        }
      }

      .divider {
        align-items: center;
        display: flex;
        &:after {
          content: "";
          border-right: 1px solid $light-blue-border;
          margin: 0 0.5rem;
          display: inline-block;
          height: 100%;
        }
      }
    }
  }
}
