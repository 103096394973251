@import '../../../../config/constants.scss';

.sort-inventory {
  border-radius: 5px!important;

  // .list {
  //   background-color: white !important;
  //   padding: 15px 20px;
  //   margin: 0!important;
  //   border-left: solid 1px transparent;
  //   border-right: solid 1px transparent;
  // }

}
