.simpleModal {
  width: 300px !important;

  .modal-header {
    border-bottom: 1px solid #dce8f2;
  }
  &.mobile {
    width: auto !important;
    margin: 70% 10px;
  }

  button {
    width: 100%;
  }
}
