@import '../../../../config/constants.scss';

.pos-list {
  border-radius: 5px!important;

  .pos-item {
    background-color: white !important;
    padding: 25px 20px;
    margin: 0!important;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;

    .text-gray{
      color: $light-blue-text !important;
      font-size: 16px;
      font-weight: 400;
    }

  }

}
