@import '../../../../../config/constants.scss';

.place-order-list {
  border-radius: 5px !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  padding-left: 2rem  !important;
  padding-right: 2rem  !important;  

  &.is-mobile {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .support-text{
    font-size: 17px;
    color: #143357;
  }

  .list-item {
    background-color: white !important;
    padding: 25px 20px;
    &.is-mobile {
      padding: 12px 20px !important;
      margin-bottom: 0px!important;
    }    
    margin: 0!important;    
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;

    .input{
      height: 40px !important;
      width: 100%;
    }

    .wc-img {
      display: flex;
      align-items: center;
      justify-content: center;      
      width: $imageDesktop;
      height: $imageDesktop;
      // position: relative;
      &.is-mobile {
        width: $imageMobile !important;
        height: $imageMobile !important;
      }      
    }

    .w-img {
      box-sizing: content-box;
      text-align: center;
      background-position:50% 50%;
      border:0;
      max-width: $imageDesktop;
      max-height: $imageDesktop;
      width: auto;
      height: auto;
      &.is-mobile {
        max-width: $imageMobile !important;
        max-height: $imageMobile !important;
      } 
    }

    .icons{           
      line-height: 1rem;
      color:$dark-blue-tag-text;
      letter-spacing: 1px!important;
      font-weight: 600;
      font-size: 10px !important;           

      .left{
        display: flex;
        flex-direction: row;
        align-items: flex-start; 
        justify-content: flex-start;
        position: absolute;     
        top: 0; 
        left: 0;

        .similar-match-tag{
          background: url("../../../../../assets/images/savings.png") no-repeat;
          background-position: left center;
          height:27px;
          padding:0px 30px 4px 4px;
        }
  
        .exact-match-tag{
          background: url("../../../../../assets/images/exact-match.png") no-repeat;
          background-position: left center;
          height:27px;
          padding:0px 30px 4px 4px;
        }
      }

      .right{
        display: flex;
        flex-direction: row;
        align-items: flex-start; 
        justify-content: flex-end;
        position: absolute;     
        top: 0; 
        right: 0;

        .order-guide-tag{
          background: url("../../../../../assets/images/bookmark-red.png") no-repeat;
          background-position: right center;
          height:20px;
          padding:4px 15px 4px 4px;
        }
  
        .purchase-tag{
          background: url("../../../../../assets/images/bookmark-green.png") no-repeat;
          background-position: right center;
          height:20px;
          padding:4px 15px 4px 4px;
        }
  
        .unpurchase-tag{
          background: url("../../../../../assets/images/bookmark-lightblue.png") no-repeat;
          background-position: right center;
          height:20px;
          padding:4px 15px 4px 4px;
        }

        .electronic-tag{
          background: url("../../../../../assets/images/lightning-bolt.svg") no-repeat;
          background-position: right center;
          height:20px;
          padding:4px 15px 4px 4px;
        }
      }
    }

    .market-price{
      border:1px solid $light-blue-mkt;
      border-radius: 15px;
      padding:2px 10px ;
      color: $light-blue-mkt;
      margin:5px 0px;
      font-weight: 500;
      font-size: 14px;
    }

    .overlay{
      height: 9em !important;
    }
  }

  .main-outline{
    border-top: solid 1px transparent;
    border-bottom: solid 1px #dce8f2;
  }

  .unpurchaseable-outline{
    border : solid 1px $fb-light-blue-tag !important;    
  }

  .similar-match-outline{
    border : solid 1px $yellow !important;    
  }
  .exact-match-outline{
    border : solid 1px $orange !important;    
  }
  .cero-match-outline{
    border : solid 1px $light-blue-sav !important;    
  }
}


