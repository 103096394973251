@import '../../config/constants.scss';

.box{
  position: absolute;
  z-index:9999999;  
  overflow: hidden;
  right: 0;
   
  top: 0.2em;
  border-radius: 5px;
  border: none; 
  
  background: $black;
  opacity: 0.75;
  color: $white; 
  
  width: 20em;
  height: 5em;
  padding: 0.5em;
  
  .img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
  }

}


