@import "../../config/constants.scss";

.list-group-wrapper {
  padding: 1rem;
  border-bottom: 1px solid $light-blue-border;
  border-right: 1px solid $light-blue-border;

  &.no-border {
    border-right: none !important;
  }

  .list-group-header {
    text-align: center;
    color: $dark-blue-text;
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $light-blue-border;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .list-group-items {
    padding: 1rem / 2;

    .list-group-item {
      border: 1px solid $light-blue-border;
      border-radius: 5px;
      margin: 1rem 0;
      padding: 2rem 1rem;
    }
  }

  &:first-child .list-group-items {
    padding-left: 1rem;
  }

  &:last-child .list-group-items {
    padding-right: 1rem;
  }
}
