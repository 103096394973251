@import "../../../../config/constants.scss";
@use "sass:color";

.analytics {
  .analytics-header {
    color: $dark-blue-text;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .analytics-tab {
    font-size: 10px;
    font-weight: 500;
    color: $dark-blue-text;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 14px;
    padding-top: 8px;
    padding-bottom: 20px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.active-tab {
      border-bottom: solid 2px #006dba !important;
    }
  }

  .analytics-stat {
    font-family: "Roboto", sans-serif;
    letter-spacing: 0;
    font-size: 14px;
    color: #376386;
    line-height: 12px;
  }

  .font-size-36 {
    font-size: 36px;
  }

  .border {
    border: solid 1px $light-blue-border !important;
  }

  .border-right {
    border-right: solid 1px $light-blue-border !important;
  }

  .border-left {
    border-left: solid 1px $light-blue-border !important;
  }

  .border-top {
    border-top: solid 1px $light-blue-border !important;
  }

  .border-bottom {
    border-bottom: solid 1px $light-blue-border !important;
  }

  label {
    color: $dark-blue-text;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
  }

  .analytics-savings-name {
    font-size: 15px;
    color: $dark-blue-text;
    line-height: 20px;
  }

  .analytics-grid-header {
    font-size: 14px;
    color: #a6b7c4;
    line-height: 14px;

    &.active {
      color: $dark-blue-text;
      border-bottom: solid 2px $blue !important;
    }
  }

  .analytics-grid-detail {
    font-size: 16px;
    color: #143357;
    line-height: 16px;
  }

  .analytics-lookup-header {
    color: $light-blue-text-bold;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
  }

  .analytics-lookup-detail {
    color: $dark-blue-text;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
  }

  .purchased-icon-analytics {
    right: 125px;
    background: url("../../../../assets/images/bookmark-green.png") right center
      no-repeat;
    height: 20px;
    padding: 4px 15px 4px 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    color: $dark-blue-tag-text;
    letter-spacing: 1px;
    text-align: right;
  }

  .order-guide-icon-analytics {
    right: 10px;
    background: url("../../../../assets/images/bookmark-red.png") right center
      no-repeat;
    height: 20px;
    padding: 4px 15px 4px 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    color: $dark-blue-tag-text;
    letter-spacing: 1px;
    text-align: right;
  }

  .list-group-header {
    font-family: "Roboto", sans-serif;
  }

  .list-group-wrapper {
    .list-group-item {
      padding: 2rem 0;

      &.extra-padding {
        padding-bottom: 4rem !important;
      }

      .item-bookmark-float {
        position: absolute;
        top: -2.2rem;
        right: 1rem;

        .item-savings-amount {
          margin-top: 0.4rem;
          text-transform: uppercase;
          color: $red;
          font-weight: 600;
        }
      }

      .item-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-height: 4rem;
          max-width: 100%;
        }
      }

      .item-name {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: uppercase;

        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .item-name,
      .item-package-details {
        color: $dark-blue-text;
      }

      .item-manufacturer-details {
        color: $light-blue-text-bold;

        .item-manufacturer-name,
        .item-manufacturer-distributor {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.5rem;
          height: 1.5rem;
        }
      }

      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .item-estimated-savings {
        text-align: center;
        align-items: center;
        border-top: 1px solid $light-blue-border;
        height: 3rem;
        margin-bottom: -2rem;
        margin-top: 1rem;
        color: $light-blue-text-bold;
        text-transform: uppercase;
        font-size: 0.9em;
      }

      .item-savings-add-to-cart-wrapper {
        position: absolute;
        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: calc(100% + 2rem);
        top: -2rem;
        left: 0;
        background-color: transparentize($color: $white, $amount: 0.15);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 2rem;
      }
    }
  }

  .load-more-items {
    color: $green;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 18px;
      line-height: 25px;

      .icon {
        margin-left: 0.5rem;
      }
    }
  }
}
