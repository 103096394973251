.fb-select {
  .select {
    &__control {
      height: 34px;
      border-color: $light-blue-border;
      border-radius: 2px;
      &--is-focused {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      }
      flex-wrap: nowrap;
    }
    &__placeholder {
      font: inherit;
    }
    &__menu {
      border-radius: 0 0 2px 2px!important;
      border: 1px solid $light-blue-border!important;
      box-shadow: none!important;
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding-left: 0;
    }
  }
}
.select__control:hover {
  border-color: $light-blue-border!important;
}
.fb-select.bg-white {
  .select {
    &__control {
      background-color: white;
    }
    &__single-value {
      color: #495057;
    }
  }
}