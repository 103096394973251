@import "../../../../../config/constants.scss";

.recipe-costing-table-row {
  th:first-child {
    vertical-align: middle;
    text-align: center;
  }

  .form-check-input {
    position: unset;
    margin: 0 auto;

    &:before {
      background-color: $green;
    }
  }
}
