@import '../../../../config/constants.scss';

.notification {
  text-decoration: none;  
  position: relative;
  display: inline-block;

  .badge {
    position: absolute;
    height: 2.3em !important;
    width: 2.3em !important;
    top: -5px;
    right: 0px;
    padding: 6px 6px;
    border-radius: 1.25em !important;
    background: $red;
    font-size: 8px;
    color: $white;
    
  }
}
