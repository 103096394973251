@import '../../../../config/constants.scss';

.order-list {
  border-radius: 5px!important;
  margin: 0;
  padding: 0;
  &.is-mobile{
    padding: 0 !important;
  }

  .order-item{
    margin: 0!important;
    padding: 15px 20px;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
  }

  .size-14{
    font-size: 14px !important;
  }
  .button{
    line-height: 1.55em !important;
  }
}





//Order Receive
.or-row-header {
  background: $dark-blue-text !important;
  color: $white;
  font-size: 16px;
  border-radius: 5px 5px 0 0;
}

.ov-row-header {
  background: $yellow !important;
  color: $white;
  font-size: 16px;
  border-radius: 5px 5px 0 0;
}

.oh {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  line-height: 1rem;
  color:$dark-blue-tag-text;
  letter-spacing: 1px!important;
  font-weight: 600;
  position: absolute;
  top: 0.2em;
  right: 0;
  font-size: 10px !important;
  padding-right: 15px;

  .special-request {
    background: url('../../../../assets/images/bookmark-blue.png') no-repeat;
    background-position: right center;
    height: 20px;
    padding: 4px 15px 4px 4px;
  }

  .electronic-invoice {
    background: url('../../../../assets/images/lightning-bolt.svg') no-repeat;
    background-position: right center;
    height: 20px;
    padding: 4px 15px 4px 4px;
  }

  .order-guide {
    background: url('../../../../assets/images/bookmark-red.png') no-repeat;
    background-position: right center;
    height: 20px;
    padding: 4px 15px 4px 4px;
  }

  .is-edit {
    background: url('../../../../assets/images/edit-dark.png') no-repeat;
    background-position: right center;
    height: 20px;
    padding: 4px 18px 4px 4px;
  }

  .order-none {
    background-position: right center;
    height: 20px;
    padding: 4px 10px 4px 4px;
  }
}

.oh-line-box {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.oh-text-area {
  background-color: white;
}

.oh-special-request-border {
  border: 1px solid #006dba !important;
}

.wc-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $imageDesktop;
  height: $imageDesktop;
  // position: relative;
  &.is-mobile {
    width: $imageMobile !important;
    height: $imageMobile !important;
  }
}
