@import "../../../config/constants";

.startInventoryModal {
  width: 600px !important;
  max-width: 700px !important;

  .modal-header {
    color: $dark-blue-text;
  }
  .modal-body {
    margin: auto;
    .row {
      margin-bottom: 10px;
      padding: 0px 20px;
    }
    svg {
      margin: auto;
      color: #6ed79a;
    }
    p {
      font-size: 16px;
      color: $dark-blue-text;
      text-align: justify;
    }
    h1 {
      font-size: 17px;
      text-align: center;
      margin: 10px auto;
      color: $dark-blue-text;
      font-weight: 600;
    }
    .fb-select {
      margin-top: 10px;
      margin-bottom: 30px;
      width: 70%;
      font-size: 13px;
      color: $dark-blue-text;
    }
    input {
      border: 1px solid #9bb1c2;
      border-radius: 4px;
      padding: 9px;
      width: 137%;
      font-size: 16px;
      color: $dark-blue-text;
    }
  }
  &.mobile {
    width: auto !important;
    margin: 70% 10px;
  }
  .contentButton {
    margin-top: 40px;
    justify-content: space-between;
    button {
      width: 180px;
      padding: 12px;
    }
    .cancel {
      width: 110px;
      background: white;
      color: #6dd89a;
    }
  }
}
