@import '../../../../../config/constants.scss';

.shopping-list {
  border-radius: 5px !important;

  .list-item {
    background-color: white !important;
    padding: 15px 20px;
    margin: 0 !important;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;

    &.dim {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.04);
    }

    h4.item-name {
      color: $dark-blue-text;
      font-size: 17px;
      font-weight: 500;
      margin: 0 !important;
    }

    .additional-info {
      color: $dark-blue-text;
      margin-top: 7px !important;

      &.brand-distributor {
        opacity: .8;
      }

      &.price {
        font-weight: bold;
        font-size: 17px !important;
      }
    }
  }

}
