@import "../../../config/constants";

.selectModal {
  .modal-header {
    // border-bottom: 1px solid #dce8f2;
    .modal-title {
      font-size: 15px;
      color: $dark-blue-text;
      opacity: 0.8;
    }
  }
  .modal-body {
    padding: 10px 40px;
    margin-bottom: 100px;
    p {
      font-size: 14px;
      color: $dark-blue-text;
      opacity: 0.8;
      text-align: center;
    }
    .fb-select {
      margin: auto;
      font-size: 13px;
      color: $dark-blue-text;
    }
  }
  .modal-footer {
    border: none;
    button {
      width: 150px;
      height: 40px;
    }
  }
  &.mobile {
    width: auto !important;
    margin: 50% 10px;
  }
}
