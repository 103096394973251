.contentGroupRI {
    .priceItem {
        position: relative;
        right: 35px;
        color: #255175 !important;
    }
    .priceItemDetail {
        color: #255175 !important;
    }
    a {
        font-size: 13px;
        font-weight: bold;
    }
    p {
        margin-top: auto;
        margin-bottom: auto;
    }
    .itemHeader {
        background-color: #eef3f6;
        border: none;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        p {
            opacity: 0.6;
        }
    }
    .itemFooter {
        background-color: #eef3f6;
        border: none;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;

        .mr5 {
            margin-right: 35px;
        }
    }
    .subItem {
        padding-left: 30px !important;
        background-color: #eef3f6;
        // border: none;
        // border-top-left-radius: 8px !important;
        // border-top-right-radius: 8px !important;
        span {
            margin-top: auto;
            margin-bottom: auto;
            color: #8aa5bf;
            text-transform: uppercase;
            font-size: 11px;
            letter-spacing: 0.1em;
        }
        p {
            color: #8aa5bf;
        }
    }
    .subItemGroup {
        background-color: white;
        padding-left: 25px;

        span {
            width: 200px;
            color: #255175;
            font-size: 15px;
            text-transform: capitalize;
        }
        p {
            color: #5a7e9b;
            // max-width: 70px;
        }
        .mxw-200 {
            max-width: 200px;
        }
        .ww-200 {
            // width: 200px;
        }
        .mxw-300 {
            max-width: 217px;
        }

        .contentDetailOnHand {
            color: #255175;
            font-size: 13px;
        }
        .lineSpace {
            width: 1px;
            height: 30px;
            background-color: #a1b5c6;
            opacity: 0.8;
        }
    }
}
.buttonComplete {
    padding: 30px;
    height: 45px;
}
