@import 'bootstrap/scss/bootstrap.scss';
@import '../constants.scss';
@import './Button.scss';
@import './Input.scss';
@import './Text.scss';
@import './Modal.scss';
@import './Select.scss';
@import './Scrollbar.scss';
@import './Card.scss';
@import './Tab.scss';
@import './Zindex.scss';
@import './Border.scss';
@import './Flex.scss';
@import './Animations.scss';

.overflow-x-hidden {
  overflow-x: hidden;
}

* {
  font-family: Roboto, sans-serif;
}

.w-fc {
  max-width: fit-content;
}

.w-80px {
  max-width: 80px;
}

.w-100px {
  max-width: 100px;
}

.w-200px {
  max-width: 200px;
}

.w-250px {
  max-width: 250px;
}

.w-260px {
  max-width: 260px;
}

.mw-260px {
  min-width: 260px;
  max-width: 260px;
}

.w-300px {
  max-width: 300px;
}

.w-320px {
  max-width: 320px;
}

.w-360px {
  max-width: 360px;
}

.w-500px {
  max-width: 500px;
}

.clickable {
  cursor: pointer;
}

.dragable {
  cursor: move;
}

.h-300px {
  max-height: 300px;
}

.hover-light:hover {
  background-color: $gray-100;
}

.h-anim {
  max-height: 10000px;
  transition: max-height 0.30s ease;
}

.h-0 {
  max-height: 0;
  overflow: hidden;
}

.dim {
  opacity: .5;
  background: rgba(0,0,0,.04);
}

.no-image {
  background: url("../../assets/images/no-image.png") no-repeat;
  background-position:50% 50%;
  border:0;
  max-width: $imageDesktop;
  max-height: $imageDesktop;
  &.is-mobile {
    width: $imageMobile !important;
    height: $imageMobile !important;
  }
}

.w-image {
  box-sizing: content-box;
  text-align: center;
  min-width: $imageDesktop;
  max-width: $imageDesktop;
  max-height: $imageDesktop;
  &.is-mobile {
    width: $imageMobile !important;
    height: $imageMobile !important;
  }
}

.w-image-sm {
  box-sizing: content-box;
  text-align: center;
  max-width: $imageMobile;
  max-height: $imageMobile;
  .no-image {
    max-width: $imageMobile;
    max-height: $imageMobile;
  }
}

.mh-image {
  min-height: 90px;
}

@media only screen and (max-width: 768px) {
  .mh-image {
    min-height: 60px;
  }
  .w-image{
    max-width: $imageMobile;
    min-width: $imageMobile;
    max-height: $imageMobile;
    .no-image {
    max-width: $imageMobile;
    max-height: $imageMobile;
    }
  }
}

.mh-20px {
  min-height: 20px;
}

.mh-90px {
  min-height: 90px;
}

.mh-300px {
  min-height: 300px;
}

.s-cb {
  box-sizing: content-box;
}

.fixed-down {
  position: fixed;
  bottom: 0;
}

.squared-35px {
  max-width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
}

.circle-sm {
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.border-dash {
  background: linear-gradient(to right, $light-blue-text 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($light-blue-text 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, $light-blue-text 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($light-blue-text 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 12px 1px, 1px 12px;
}

.h-inherit {
  height: inherit!important;
}

.no-focus-outline:focus {
  outline: none;
}

.company-logo {
  max-width: 150px;
  max-height: 70px;
}

.square-img {
  max-width: 20px;
  max-height: 20px;
  padding: 0;
}