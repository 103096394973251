@import '../../../../config/constants.scss';

.credit-card-list {
  border-radius: 5px!important;

  .credit-card {
    background-color: white !important;
    padding: 25px 20px;
    margin: 0!important;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
  }

  &.is-mobile {
    .credit-card {
      padding: 15px 10px;
    }
  }

}
