@import '../../../../../config/constants.scss';

legend {
  width: 100%;
  margin-bottom: 22px;
  font-size: 24px;
  line-height: inherit;
  border-bottom: 1px solid #e5e5e5;
}

.StripeElement {
  color: $dark-blue-text !important;
  padding: 15px;
  background: #fff;
  border: solid 1px #dce8f2 !important;
}
