.card {
  border: none;
  border-bottom: 1px solid $light-blue-border;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  border-radius: 0px;
}

.card:first-of-type {
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.card:last-of-type {
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.card:only-of-type {
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .card {
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-top-left-radius: 0px!important;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
  }
}