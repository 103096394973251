@import "../../config/constants.scss";

.col.sidebar {
  background-color: $dark-blue;
  min-height: 100vh;
  max-width: 300px;
  z-index: 100;
  transition: all ease-in-out 0.2s;
  position: fixed;
  left: -300px;
  &-open {
    left: 0px;
  }
  .sidebar {
    &-header {
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 500;
      padding: 20px;
      background-color: $darker-blue;

      .active-location {
        line-height: 20px;
      }
    }

    &-locations {
      color: white;
      top: 80px;
      padding-top: 10px;
      bottom: 0;
      background-color: rgba($color: $darker-blue, $alpha: 0.8);
      .row {
        padding: 7px 20px;
      }
    }

    &-body{      
      top: 5em;
      bottom: 0;
      position: absolute;
      transition: all .3s ease-in;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    &-body::-webkit-scrollbar-track {
      background: $dark-blue !important;
    }
    &-body::-webkit-scrollbar {
      width: 8px;
    }
  }

  h4.fb-heading-title,
  .cantfind_txt {
    color: $white;
    font-size: 14px;
    opacity: 0.5;
    font-weight: 300;
  }

  h4.fb-heading-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .main-navigation {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
    li a {
      padding-left: 30px;
      color: #fff;
      font-size: 15px;
      line-height: 32px;
      font-weight: 300;
      text-decoration: none;
      position: relative;
      svg {
        position: absolute;
        left: 0;
        top: 0;
      }
      &:hover, &.active {
        color: $green;
        svg {
          color: $green !important;
        }
      }
      &.place-order-menu {
        background: url("../../assets/images/place-order-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/place-order-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.accounting-menu {
        background: url("../../assets/images/accounting-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/accounting-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.order-history-menu {
        background: url("../../assets/images/order-history-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/order-history-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.order-guide-menu {
        background: url("../../assets/images/order-guide-menu.svg") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/order-guide-menu-on.svg") no-repeat;
          background-position:0 50%;
        }
      }
      &.pos-menu {
        background: url("../../assets/images/pos-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/pos-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.recipe-costing-menu {
        background: url("../../assets/images/recipe-costing-menu.svg") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/recipe-costing-menu-on.svg") no-repeat;
          background-position:0 50%;
        }
      }
      &.shopping-lists-menu {
        background: url("../../assets/images/shopping-lists-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/shopping-lists-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.help-report-issue-menu {
        background: url("../../assets/images/help-report-issue-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/help-report-issue-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.log-out-menu {
        background: url("../../assets/images/log-out-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/log-out-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.welcome-tour-menu {
        background: url("../../assets/images/welcome-tour-menu.png") no-repeat;
        background-position: 0 50%;
        cursor: pointer;
        &:hover, &.active {
          background: url("../../assets/images/welcome-tour-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
      &.tour-comparison-menu {
        background: url("../../assets/images/tour-comparison-menu.png") no-repeat;
        background-position: 0 50%;
        &:hover, &.active {
          background: url("../../assets/images/tour-comparison-menu-on.png") no-repeat;
          background-position:0 50%;
        }
      }
    }
  }

  .special-request-button {
    color: $white;
    font-size: 18px !important;
    height: 45px !important;
    background-color: #006dba !important;
    border-color: transparent !important;
    -webkit-border-radius: 4px !important;
    border-radius: 4px;
    display: block;
  }
}
