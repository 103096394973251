.inputCounter {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px !important;
  p {
    font-weight: 600;
    font-size: 14px;
    margin-top: 14px;
    margin-left: 18px;
    margin-right: 18px;
  }
  .buttonDelete {
    position: absolute;
    top: -35px;
    left: 6px;
    font-size: 16px;
  }
}
