@import '../../../../../config/constants.scss';

.take-photo{
  overflow: hidden !important;
  background: $black !important;
  color: $green;
  font-size: 12px;
  height: 56em;
  border: none;

  .green-border{
    border: 1px solid $green;
  }

  .scaled{
    max-width: 100%;
    max-height: 320px;
  }

  .hide{
    position: fixed; 
    top: 0; 
    left: 150%;
  }

  .canvas{
    margin-bottom: 80px; 
    overflow-x: overlay;
    .snap{
      width: 100px; 
      margin-right: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  
  .option{
    color: $white;
    opacity: 0.7;
    font-size: 1.5em;
  }
}