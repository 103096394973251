@import "../../../../../config/constants.scss";

.specialImg {
  width: 109px;
  position: absolute;
  right: 1px;
  top: -2px;
}
.contentOrdering {
  padding: 20px 30px;

  img {
    margin-top: 2px;
    max-width: 90px;
    max-height: 90px;
  }
  .contentRow {
    margin-bottom: 50px;
  }
  h1 {
    text-align: start;
    font-weight: 400;
    font-size: 17px !important;
    color: $dark-blue-text;
    margin: 10px 0px 30px 0px;
  }
}

.contentDetails {
  padding-top: 25px;
  padding-bottom: 20px;
  text-align: center;
  .content-align-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .height20 {
    height: 20px !important;
  }
  .height35 {
    height: 35px !important;
  }
  .details {
    text-align: initial;
    .row {
      height: 27px;
      text-align: inherit;
      .col {
        padding: 0px;
      }
    }
    h3 {
      font-size: 13px;
      font-weight: 600;
      color: $dark-blue-text;
      text-transform: capitalize;
    }
    p {
      opacity: 0.8;
      color: $dark-blue-text;
      font-size: 13px;
    }

    &.floatRight {
      position: absolute;
      right: 23px;
      top: 31px;
      text-align: center;
    }

    &.special {
      margin-top: 14px;
      .row {
        margin-top: 3px;
      }
      &.floatRight {
        right: 32px;
        top: 50px;
      }
    }
  }

  &.mobile {
    margin: 0px 0px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    h3,
    p {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p {
      font-size: 11px !important;
    }
    .cantItem {
      position: absolute;
    }
    .floatRight {
      text-align: center;
      top: 38px;
    }
    .btn-lg-circle {
      height: 37px;
      width: 39px;
    }
    &.isEspecial {
      padding-top: 25px;
    }
  }
}

.review-order-card {
  // box-shadow: none !important;
  .card-header {
    background: $dark-blue-text !important;
    color: #fff;
    font-size: 14px;

    span {
      float: right;
      position: relative;
    }
  }
  .card-body {
    h1 {
      opacity: 0.5;
      color: $dark-blue-text;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 12px !important;
    }
    p {
      color: $dark-blue-text;
      font-size: 12px;
    }
    .text-end {
      text-align: end;
    }
    span {
      color: #006dba;
      font-size: 11px;
    }
    .buttonCheck {
      width: 100%;
      height: 45px;
    }
    .mbot {
      margin-bottom: 22px;
    }

    .inline {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;

      .marginIcon {
        margin-left: 10px;
      }
    }
  }

  .review-order-footer {
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: -6px;
    background: url("../../../../../assets//images/review_order_footer_image.png") repeat-x;
    height: 10px;

    &.mobile {
      bottom: -3px;
    }
  }

  &.mobile {
    box-shadow: none;
    border: none;
    margin-bottom: 20px;
    .card-header {
      font-size: 19px;
      height: 66px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    .card-body {
      // margin: 10px 0px;
      padding: 0.6rem;
      p {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 12px;
      }
      .faSortDown {
        font-size: 17px;
        position: relative;
        bottom: 4px;
      }
      .faSortUp {
        font-size: 17px;
        position: relative;
        bottom: -4px;
      }

      .faPen,
      .faCalendar {
        font-size: 17px;
        color: $dark-blue-text;
        margin-top: 5px;
      }
      .totalSubTotal {
        text-align: center;
        margin-top: 22px;
        color: #143357;
        font-weight: 600;
        font-size: 14px;
      }
    }
    hr {
      margin: 0px 0px 15px;
    }
    input {
      font-size: 12px;
    }
    button {
      width: 100%;
      font-size: 12px;
    }
    .buttonDelivery {
      border: 2px dashed;
      border-color: #dce8f2;
      text-align: left;
      height: 40px;
    }
    .fb-select {
      font-size: 12px;
    }
  }
}

.cardProgress {
  height: 76px;
  padding-top: 21px;
  .textProgress {
    text-align: center;
    position: relative;
    top: 8px;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: $dark-blue-text;
    // color: #006dba;
    margin-bottom: -36px;
    text-transform: uppercase;
    svg {
      font-size: 25px;
      position: relative;
      top: -2px;
      left: -12px;
    }
  }

  .progress {
    background-color: white;
    margin: 15px 25px;
    height: 2.5em;
    border: 1px solid #6ed79a63;

    .progress-bar {
      background-color: $green;
    }
  }

  &.mobile {
    padding-top: 4px;
    height: 46px;
    .textProgress {
      top: 6px;
      letter-spacing: 0.1em;
      font-size: 9px;
      svg {
        font-size: 20px;
      }
    }
    .progress {
      margin: 15px 0px;
    }
  }
}
.headerIcon {
  position: absolute;
  right: -18px;
  width: 250px;
  bottom: 0px;
  .iconText {
    background: url("../../../../../assets/images/bookmark-green.png") no-repeat;
    width: 117px;
    font-size: 8px !important;
    color: $dark-blue-tag-text;
    text-transform: uppercase;
    letter-spacing: 1px !important;
    font-weight: 600;
    position: absolute;
    top: 9px;
    left: 7px;
  }
  .iconTextM {
    top: 3px !important;
  }

  .right {
    background-position: right center;
    padding: 4px 15px 4px 4px;
  }
  .left {
    background-position: left center;
    // padding: 4px 0px 0px 7px;
    padding: 2px 0px 0px 7px;
  }
  .textDate {
    font-size: 8px !important;
    color: $dark-blue-tag-text;
    text-transform: uppercase;
    letter-spacing: 1px !important;
    font-weight: 600;
    margin-left: 112px;
    position: relative;
    top: 2px;
  }
}
.contentDetailItem {
  margin: 15px 30px;

  .headerContent {
    .headerRight {
      display: flex;
      position: absolute;
      width: 50%;
      top: 0;
      right: 0px;
      border-top-right-radius: 4px;
      border-bottom: solid 2px #dce8f2;
      border-left: solid 2px #dce8f2;
      height: 50px;
      div {
        text-align: center;
        width: 50%;
        em,
        svg {
          font-size: 12px;
          color: $dark-blue-text;
          position: relative;
          top: 10px;
          font-style: inherit;

          &.icon {
            position: relative;
            right: 10px;
            top: 10px;
          }
        }

        &.borderLeft {
          border-left: solid 2px #dce8f2;
        }
      }
    }
  }

  .bodyContent {
    margin: 70px 30px 0px 30px;
    color: $dark-blue-text;

    img {
      max-width: 90px;
      max-height: 90px;
    }
    .textDetail {
      h1 {
        font-size: 17px;
        font-weight: 600;
        opacity: 0.9;
        margin-bottom: 30px;
        text-transform: capitalize;
      }
      p {
        opacity: 0.5;
        font-size: 14px;
        font-weight: 600;
      }
      span {
        font-size: 13px;
      }
    }
    .counterContent {
      margin: auto;
      .btn-green {
        width: 100% !important;
      }
    }
  }

  .FooterContent {
    color: $dark-blue-text;
    margin: 35px 0px 0px 20px;

    h1 {
      font-size: 15px;
      font-weight: 600;
      opacity: 0.9;
      margin-bottom: 30px;
      margin-left: 2px;
    }

    svg {
      font-size: 16px;
      margin-right: 10px;
    }

    .-mTop {
      margin-top: -4px;
    }
    .-mBottom {
      margin-bottom: -25px;
    }
    textarea {
      height: 100px;
    }
  }
}
.contentOrderingMobile {
  margin: 20px 3px;
}

.mt25 {
  margin-top: 25px;
}

.displayNone {
  display: none;
}

.modalDistributor {
  .contentAll {
    margin-bottom: 25px;
  }
  .modal-body {
    padding: 1.5em 2rem;
    // height: 594px;
    overflow: auto;
  }
  .modal-header {
    padding: 10px 20px 26px 10px;
    border-bottom: 1px solid #f3eded;
    h5 {
      font-weight: 600;
      font-size: 17px !important;
      color: $dark-blue-text;
    }
    .modal-title {
      margin-top: -16px;
    }
  }
  .card-header {
    background-color: #143357;
    h3 {
      color: white;
      text-align: start;
      font-weight: 400;
      font-size: 15px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .titleGroup {
    display: flex;
    flex-direction: row;
    margin-bottom: 23px;
    align-items: center;
    h3 {
      flex: 0.4;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      color: #376386;
    }
    div {
      flex: 0.3;
      height: 1px;
      background: #dce8f2;
      width: 90%;
    }
  }
  .card-body {
    .contentImg {
      height: 140px;
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        max-width: 90px;
        max-height: 90px;
        margin-bottom: 15px;
      }
    }

    p {
      opacity: 0.8;
      color: $dark-blue-text;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    h1 {
      font-size: 13px;
      font-weight: 600;
      color: $dark-blue-text;
      text-transform: capitalize;
    }
    .contentButton {
      text-align: center;
      margin: 20px 0px 0px;
    }
  }

  .flex-content {
    margin: 0;
    flex-wrap: nowrap !important;
    overflow: hidden !important;
  }

  .arrowLeft {
    position: absolute;
    margin-top: 22%;
    left: 10px;
  }
  .arrowRight {
    position: absolute;
    margin-top: 22%;
    right: 10px;
  }

  &.mobile {
    // .modal-content {
    //   max-height: 540px;
    // }

    .arrowLeft {
      position: absolute;
      margin-top: -48px;
      left: 4px;
      height: 20px;
    }
    .arrowRight {
      position: absolute;
      margin-top: -48px;
      right: 4px;
      height: 20px;
    }
  }
}

.contentShippingDetail {
  svg {
    color: $dark-blue-text;
  }
  p {
    margin-top: 5px;
    margin-left: 10px;
  }
}

.swipeContentPunchedOut {
  background: red;

  div div:nth-child(2) {
    background-color: #eef2f5;
  }
}

.swipeContent {
  background: red;

}
.swipeText {
  margin-right: 40px;
  svg {
    font-size: 20px;
  }
  h1 {
    margin-top: 5px;
    color: white !important;
    opacity: 1 !important;
    font-size: 14px;
  }
}

.select-group {
  padding-top: 10px;
  width: 220px;
  text-align: center;
  margin: auto;
  font-size: 16px;
}

.testok {
  background-color: red;
}
