@import "../../../../../config/constants.scss";

.rowInventoriesTitle {
    font-size: 15px;
}
.rowITemInventories {
    font-size: 14px;
    color: $dark-blue-text;
    opacity: 0.8;

    span{
        opacity: 0.7;
    }
    .actionItemInventories {
        display: flex;
        align-items: center;
        p {
            margin: 0px 0px 0px 5px;
            opacity: 0.7;
            cursor: pointer;
        }
        path {
            cursor: pointer;
        }
    }
}
