.arrowLeft:after {
  content: "";
  position: absolute;
  left: -10px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-right-color: #fff;
  color: transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: 19px;
}
.arrowRight:after {
  content: "";
  position: absolute;
  right: -10px;
  border-style: solid;
  border-width: 10px 0px 10px 10px;
  border-left-color: #fff;
  color: transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: 19px;
}
.arrowTop:after {
  content: "";
  position: absolute;
  left: -10px;
  display: block;
  width: 0;
  z-index: 1;
  top: -12px;
  left: 130px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid rgb(255, 255, 255);
}
.arrowTopRight:after{
  content: "";
  position: absolute;
  left: -10px;
  display: block;
  width: 0;
  z-index: 1;
  top: -12px;
  left: 254px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid rgb(255, 255, 255);
}

.modalTour {
  width: 300px !important;
  color: #376386;

  .modal-body {
    padding: 2rem;
  }
  h1 {
    font-weight: 350;
    line-height: 48px;
    font-size: 28px;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    button {
      padding: 10px 37px 10px 37px;
      border: none;
      margin: auto;
    }
    span {
      margin: 20px auto 0px auto;
      cursor: pointer;
    }
  }
  .imgLeft {
    position: absolute;
    left: -59px;
    top: 11px;
  }
  .imgTop {
    position: absolute;
    left: 126px;
    top: -52px;
  }
  .imgTopRight{
    position: absolute;
    left: 250px;
    top: -52px;
  }
  .imgRight {
    position: absolute;
    right: -59px;
    top: 11px;
  }
  .hiddeImg {
    display: none;
  }
}
