@import '../../../config/constants.scss';

.rebate{
  position: absolute;
  z-index: 0;
  background: $green;
  width: 3em;
  height: 3em; 
  color: $white;  
}

.desktop{
  top: -10px !important;
  right: -5px !important;
  font-size: 12px;
}
.mobile{
  top: -20px !important;
  left: 0px !important;
  font-size: 10px;
}

.rebate::before, .rebate::after{
  content: '';
  display: inline-block;
  position: absolute;
  z-index: -1;
  background: $green;
  width: 3em;
  height: 3em;
  top: 0;
  left: 0;        
}

.rebate::before {
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
}

.rebate::after {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
}