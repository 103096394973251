@import '../../../../config/constants.scss';

.btn-bulk-upload, .btn-download-quickbooks {
  color: $green !important;
  background-color: transparent !important;
  border: 1px solid $green !important;
}

.upload-file-outline {
  color: $light-blue-text !important;
  border: dashed 1px $light-blue-text !important;
  padding: 40px 15px;
  margin: 0px 15px;
  cursor: pointer;
}

.accounting {

  .nav-tabs {

    a.nav-link {
      color: #006eb8;

      &.active {
        color: #555555;
      }
    }

  }

  .grey-text {
    color: $dark-blue-text !important;

    -moz-opacity: 0.5 !important;
    -khtml-opacity: 0.5 !important;
    -webkit-opacity: 0.5 !important;
    opacity: 0.5 !important;
  }

  .additional-info {
    color: $dark-blue-text;
    margin-top: 5px !important;

    .brand-distributor {
      font-size: 16px;
      -moz-opacity: 0.8 !important;
      -khtml-opacity: 0.8 !important;
      -webkit-opacity: 0.8 !important;
      opacity: 0.8 !important;
    }
  }

  .border-top-transparent {
    border-top: 1px solid transparent !important;
  }

  .border-bottom {
    border-bottom: 1px solid $light-blue-border !important;
  }

}
