@import "../../config/constants.scss";

.sign-up-app {
  background-color: white;
  min-height: 100vh;
  .sign-up-sidebar {
    min-height: 100vh;
    background-image: url("../../../src/assets/images/foodbam_bg.jpg");
  }
  .textSigned {
    font-family: BrushScript, BrushScript2, "Brush Script MT";
    font-size: 1.9em;
  }

  .contentPayment {
    margin-left: 15px;
    h1 {
      color: #202020;
      font-size: 25px;
      font-weight: 700;
      text-decoration: line-through;
    }
    h2 {
      font-size: 25px;
      font-weight: 700;
      color: #fa9016;
      margin-left: 10px;
    }
    h3{
      font-size: 15px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: #7f8083;
      margin-bottom: 1.2rem;
      font-weight: 400;
      text-align: left;
      margin-top: 5px;
    }
    .checkContent {
      height: 25px;
      width: 25px;
      background: #112c4b;
      text-align: center;
      border-radius: 50%;
      margin-right: 20px;

      svg {
        font-size: 11px;
      }
    }
  }
}
