@import '../../../../../config/constants.scss';

.shopping-list-item-block {
  padding: 20px;

    &.dim {
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.04);
    }

  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    min-height: 90px;
    margin-bottom: 30px;
  }

  h4.item-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0 !important;
    color: $dark-blue-text;
    text-transform: capitalize;
    margin-bottom: 20px !important;
  }

  .brand-distributor {
    font-size: 14px;
    opacity: .8;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
  }

  .item-price {
    font-size: 16px !important;
    font-weight: 600;
    opacity: 1;
  }
}
